import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"

const theme = {
}

// Wrapping component for the theme
const Reset = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalStyle />
  </ThemeProvider>
)

const GlobalStyle = createGlobalStyle`
`

export default Reset
