import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer.js"
import SEO from "./seo.js"
import Theme from "./theme.js"
import Reset from "./reset.js"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Reset>
      <Theme>
        <Header siteTitle={data.site.siteMetadata.title} />
        <SEO />
        <main>{children}</main>
        <Footer />
      </Theme>
    </Reset>
  )
}

export default Layout
