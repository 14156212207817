import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"

const theme = {
  spacing: {
    small: "80px",
    medium: "120px",
    large: "240px",
  },
  fonts: {},
  colors: {
    black: "#000000",
    white: "#ffffff",
    primary: "#071416",
    lightbackground: "#0E2327",
    orange: "#FF8F5F",
    darkorange: "#8A2B03",
    blue: "#57D7FF",
    yellow: "#FFC64F",
    green: "#4FFF88",
    textGrey: "#9E9E9E",
  },
  transitions: {
    all: "all 200ms ease-in-out",
  },
  query: {
    mobile: "@media only screen and (max-width: 480px)",
    tablet: "@media only screen and (max-width: 768px)",
    desktop: "@media only screen and (max-width: 1040px)",
  },
}

// Wrapping component for the theme
const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalStyle />
  </ThemeProvider>
)

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    font-size: 16px;
    max-width: 100%;

    * {
      box-sizing: border-box;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    margin-top: 0;
    margin-bottom: 20px;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 40px;
    font-weight: 600;
    color: ${theme.colors.white};

    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    font-size: 64px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 14px;
  }

  ul, ol {
    padding-left: 0;
    list-style-position: inside;

    li {
      font-size: 16px;
      line-height: 2;
      color: ${theme.colors.textGrey};

      a {
        color: ${theme.colors.textGrey};
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.colors.textGrey};
  }

  a {
    font-weight: 400;
    transition: ${theme.transitions.all};
    color: ${theme.colors.orange};
    text-decoration: none;
  }

  input {
    &:focus {
      outline: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  form {
    button {

    }
  }

  strong {
    font-weight: 500;
  }

  ul li strong {
    color ${theme.colors.white};
  }

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    &.is-column {
      flex-direction: column;
    }
    ${theme.query.tablet} {
      &.is-column-t {
        flex-direction: column;
      }
    }
    ${theme.query.mobile} {
      &.is-column-m {
        flex-direction: column;
      }
    }

    .column {
      box-sizing: border-box;
    }
  }

  .col-auto {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-1 {
    width: 6.5%;
  }
  .col-2 {
    width: 15%;
  }
  .col-3 {
    width: 23.5%;
  }
  .col-4 {
    width: 32%;
  }
  .col-5 {
    width: 40.5%;
  }
  .col-6 {
    width: 49%;
  }
  .col-7 {
    width: 57.5%;
  }
  .col-8 {
    width: 66%;
  }
  .col-9 {
    width: 74.5%;
  }
  .col-10 {
    width: 83%;
  }
  .col-11 {
    width: 91.5%;
  }
  .col-12 {
    width: 100%;
  }

  ${theme.query.desktop} {
    .col-1-d {
      width: 6.5%;
    }
    .col-2-d {
      width: 15%;
    }
    .col-3-d {
      width: 23.5%;
    }
    .col-4-d {
      width: 32%;
    }
    .col-5-d {
      width: 40.5%;
    }
    .col-6-d {
      width: 49%;
    }
    .col-7-d {
      width: 57.5%;
    }
    .col-8-d {
      width: 66%;
    }
    .col-9-d {
      width: 74.5%;
    }
    .col-10-d {
      width: 83%;
    }
    .col-11-d {
      width: 91.5%;
    }
    .col-12-d {
      width: 100%;
    }
  }

  ${theme.query.tablet} {
    .col-1-t {
      width: 6.5%;
    }
    .col-2-t {
      width: 15%;
    }
    .col-3-t {
      width: 23.5%;
    }
    .col-4-t {
      width: 32%;
    }
    .col-5-t {
      width: 40.5%;
    }
    .col-6-t {
      width: 49%;
    }
    .col-7-t {
      width: 57.5%;
    }
    .col-8-t {
      width: 66%;
    }
    .col-9-t {
      width: 74.5%;
    }
    .col-10-t {
      width: 83%;
    }
    .col-11-t {
      width: 91.5%;
    }
    .col-12-t {
      width: 100%;
    }
  }

  ${theme.query.mobile} {
    .col-1-m {
      width: 6.5%;
    }
    .col-2-m {
      width: 15%;
    }
    .col-3-m {
      width: 23.5%;
    }
    .col-4-m {
      width: 32%;
    }
    .col-5-m {
      width: 40.5%;
    }
    .col-6-m {
      width: 49%;
    }
    .col-7-m {
      width: 57.5%;
    }
    .col-8-m {
      width: 66%;
    }
    .col-9-m {
      width: 74.5%;
    }
    .col-10-m {
      width: 83%;
    }
    .col-11-m {
      width: 91.5%;
    }
    .col-12-m {
      width: 100%;
    }
  }

  .boxed-width {
    display: flex;
    max-width: 1344px;
    padding-left: 72px;
    padding-right: 72px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    ${theme.query.tablet} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .justify-content-start {
    justify-content: flex-start;
  }

  .justify-content-center {
    justify-content: center;
  }

  .is-direction-column {
    flex-direction: column;
  }

  .is-direction-column.justify-content-center {
    align-items: center;
  }

  .align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .align-flex-start {
    align-items: start;
  }

  .align-items-center {
    align-items: center;
  }

  &.padding-top-small {
    padding-top: ${theme.spacing.small};
  }
  &.padding-top-medium {
    padding-top: ${theme.spacing.medium};
  }
  &.padding-top-large {
    padding-top: ${theme.spacing.large};
  }
  &.padding-bottom-small {
    padding-bottom: ${theme.spacing.small};
  }
  &.padding-bottom-medium {
    padding-bottom: ${theme.spacing.medium};
  }
  &.padding-bottom-large {
    padding-bottom: ${theme.spacing.large};
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }
`

export default Theme
