import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../../images/logo.svg"

function Footer(props) {
  return (
    <StyledFooter className="padding-bottom-small">
      <div className="footer-inner boxed-width container">
        <div className="col-3 col-6-t col-12-m">
          <Logo href="/" className="logo">
            <img src={logo} alt="Footer logo for The Design Handbook" />
          </Logo>
        </div>
        <div className="col-3 col-6-t col-12-m">
          <h5>Quick links</h5>
          <StyledUnorderedList>
            <StyledListItem>
              <StyledLink to="/">Home</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink to="/get-access">Get access</StyledLink>
            </StyledListItem>
          </StyledUnorderedList>
        </div>
        <div className="col-3 col-6-t col-12-m">
          <h5>Learn</h5>
          <StyledUnorderedList>
            <StyledListItem>
              <StyledLink to="/user-guide">User guide</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink to="/glossary">Glossary</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink to="/community">Community</StyledLink>
            </StyledListItem>
          </StyledUnorderedList>
        </div>
        <div className="col-3 col-6-t col-12-m">
          <h5>Business</h5>
          <StyledUnorderedList>
            <StyledListItem>
              <StyledLink to="/about">About</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink to="/contact">Contact</StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink to="/terms-and-conditions">
                Terms and conditions
              </StyledLink>
            </StyledListItem>
            <StyledListItem>
              <StyledLink to="/privacy-policy">Privacy policy</StyledLink>
            </StyledListItem>
          </StyledUnorderedList>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

// Styles
const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Logo = styled.a`
  margin-bottom: 0;

  img {
    max-width: 163px;
    width: 100%;
    margin-bottom: 0;
  }
`

const StyledUnorderedList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`

const StyledListItem = styled.li``

const StyledLink = styled(Link)``
