import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import logo from "../../images/logo.svg"
import slackIcon from "../../images/slack-new-logo.svg"

function Header(props) {
  const [hamburger, toggleHamburger] = React.useState(false)

  return (
    <StyledHeader className={hamburger === true ? "is-active" : ""}>
      <HeaderInner className="header-inner boxed-width">
        <Logo href="/" className="logo">
          <img src={logo} alt="The design handbook logo" />
        </Logo>

        <NavWrapper
          role="navigation"
          className={hamburger === true ? "is-active" : ""}
        >
          <StyledList className={hamburger === true ? "is-active" : ""}>
            <StyledListItem
              onClick={() => {
                toggleHamburger(!hamburger)
              }}
            >
              <StyledLink to="/" className="nav-link">
                Home
              </StyledLink>
            </StyledListItem>
            <StyledListItem
              onClick={() => {
                toggleHamburger(!hamburger)
              }}
            >
              <StyledLink to="/user-guide/introduction" className="nav-link">
                Learn
              </StyledLink>
            </StyledListItem>
            <StyledListItem
              onClick={() => {
                toggleHamburger(!hamburger)
              }}
            >
              <StyledExternalLink
                href="https://join.slack.com/t/thedesignhandbook/shared_invite/zt-ontfbph0-_Vc5PIlc3KtLst3I8j1VlA"
                className="nav-link"
              >
                <SlackIcon src={slackIcon} />
                Community
              </StyledExternalLink>
            </StyledListItem>
          </StyledList>
        </NavWrapper>

        <HamburgerWrapper>
          <button
            className={
              hamburger === true
                ? "hamburger hamburger--elastic js-hamburger is-active"
                : "hamburger hamburger--elastic js-hamburger"
            }
            type="button"
            onClick={() => {
              toggleHamburger(!hamburger)
            }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </HamburgerWrapper>
      </HeaderInner>
    </StyledHeader>
  )
}

export default Header

// Styles
const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    &.is-active {
      z-index: initial;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      background-color: #1b4251;
      z-index: 5;
    }
  }
`

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 48px;
  box-sizing: border-box;
  transition: all ease-in-out 300ms;
  flex-wrap: wrap;
  box-sizing: content-box;
`

const Logo = styled.a`
  margin-bottom: 0;

  img {
    max-width: 163px;
    width: 100%;
    margin-bottom: 0;
  }
`

const NavWrapper = styled.nav`
  margin-left: auto;
  display: flex;

  @media (max-width: 768px) {
    margin-left: 0;
    align-items: center;
    flex-direction: column;
    display: none;

    &.is-active {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: static;
      top: 120px;
      left: 0;
      padding-bottom: 24px;
      width: 100%;
      order: 3;
    }
  }

  .styled-link-button {
    width: 100%;
  }
`

const StyledList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;

  @media (max-width: 768px) {
    margin-right: 0;

    &.is-active {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      width: 100%;
    }
  }
`

const StyledListItem = styled.li`
  text-align: center;
  margin-right: 48px;
  list-style-type: none;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 24px;

    a {
      font-size: 20px;
      text-align: center;
    }
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  margin-bottom: 0;
  color: ${props => props.theme.colors.textGrey};

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`

const StyledExternalLink = styled.a`
  text-decoration: none;
  margin-bottom: 0;
  color: ${props => props.theme.colors.textGrey};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`

const HamburgerWrapper = styled.div`
  display: none;
  margin-left: auto;

  @media (max-width: 768px) {
    display: block;
  }
`

const SlackIcon = styled.img`
  max-width: 16px;
  max-height: 16px;
  margin-right: 8px;
`
